import React from "react";
import { Footer } from "react-materialize";

class CustomFooter extends React.Component {
    render() {
        return (
            <section id="footer">
                <Footer
                    className="grey darken-3"
                    copyrights="© 2019 Flur"
                >
                    <h5 className="white-text">
                        Kontaktujte nás:
                    </h5>
                    <p className="grey-text text-lighten-4">
                        Adresa: Praha 8, Braunerova ulice
                    <br />
                        Telefon: 773 130 073 / 777 221 924
                    </p>
                </Footer>
            </section>
        )
    }
}

export default CustomFooter;