import React from "react";
import { NavItem, Navbar } from "react-materialize";
import Logo from "../assets/img/logo.png";

class Header extends React.Component {
    render() {
        return (
            <header>
                <Navbar
                    brand={
                        <a href="/#" className="hide-on-med-and-down"><img src={Logo} alt="logo"
                            style={{ width: "15%", marginTop: 2 }} /></a>
                    }
                    alignLinks="right"
                    className="grey darken-3"
                    fixed
                >
                    <NavItem href="#carousel">
                        Domů
                    </NavItem>
                    <NavItem href="#services">
                        Služby
                    </NavItem>
                    <NavItem href="#about">
                        O nás
                    </NavItem>
                    <NavItem href="#footer">
                        Kontakt
                    </NavItem>
                </Navbar>
            </header>
        )
    }
}

export default Header;