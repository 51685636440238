import app from "firebase/app";
import "firebase/firebase-firestore";

const config = {
    apiKey: "AIzaSyBOSZoNv_bGcCkco-w_Nwvqx71e4Q92Plw",
    authDomain: "sokolgroup-42c93.firebaseapp.com",
    databaseURL: "https://sokolgroup-42c93.firebaseio.com",
    projectId: "sokolgroup-42c93",
    storageBucket: "sokolgroup-42c93.appspot.com",
    messagingSenderId: "317722713138",
    appId: "1:317722713138:web:b3bd8ff7ff462de5"
};

class Firebase {
    constructor() {
        app.initializeApp(config);
        this.db = app.firestore();
    }

    allCarsRef = () => this.db.collection('cars');
}

export default new Firebase();

