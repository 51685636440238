import React from "react";
import { Slide, Slider, Caption, Container } from "react-materialize";
import Background from "../assets/img/bg.jpg";

class Carousel extends React.Component {
    render() {
        return (
            <section id="carousel">
                <Slider
                    options={{
                        indicators: false,
                        height: 550
                    }}
                    >
                    <Slide image={<img src={Background} alt="UberRent" style={{ WebkitFilter: "brightness(0.35)" }} />}>
                        <Caption>
                            <Container>
                                <h1 className="header center white-text">
                                    Uber<span className="red-text">Rent</span>
                                </h1>
                                <div className="row center">
                                    <h5 className="header col s12 light white-text">Nejjednoduší cesta k výdělku bez vstupních nákladů</h5>
                                    <h4 className="header col s12 light white-text">Neseď doma a pojď vydělávat!</h4>
                                    <h4 className="header col s12 light white-text">Volej <a className="red-text" href="tel:+420773130073">773 130 073</a></h4>
                                </div>
                            </Container>
                        </Caption>
                    </Slide>
                </Slider>
            </section>
        )
    }
}

export default Carousel;