import React from "react";
import { Row, Col, Divider } from "react-materialize";
import BlackCar from "../assets/img/blackcar.png";

class About extends React.Component {
    render() {
        return (
            <section id="about" className="section scrollspy">
                <h3 className="center">O nás</h3>
                <Divider />
                <Row>
                    <Col s={12} m={12} className="center pading-20">
                        <p>
                            Jsme první půjčovna vozů, která se specializuje na pronájem a zastřešení živností pro přepravu cestujících a využívá
                            moderních technologií jako např. Uber a jiné. Nabízíme nejlepší a nejlevnější podmínky s kompletním servisem a pojištěním.
                            U nás nepotřebujete kauci ani vstupní kapitál. Nebudťe otrokem u rádoby zprostředkovatelů, kteří na vás jen parazitují a
                            pronajímají auta za 5000,-tis./týdně a ještě vám vezmou 20% a více. Přidejte se k nám do teamu UberRent a udělejte ze svého
                            přivýdělku radost a nikoliv starost. <strong className="red-text darken-1">U nás se pohybují ceny od 2700,- až 4000,-/týdně.</strong>
                            Vše vám důkladně vysvětlíme od aplikace v telefonu po hladký provoz vašeho podnikání. U nás si vydělá opravdu každý !!!
                         </p>
                    </Col>
                    <Col s={12} m={12} className="center pading-20">
                        <br />
                        <img src={BlackCar} alt="black car" />
                    </Col>
                </Row>
            </section>
        )
    }
}

export default About;