import React from "react";
import { Divider, Row, Col, Icon } from "react-materialize";

class Services extends React.Component {
    render() {
        return (
            <section id="services" className="section scrollspy">
                <h3 className="center">Služby</h3>
                <Divider />
                <Row>
                    <Col s={12} m={6}>
                        <div className="icon-block center">
                            <h2 className="center red-text darken-1">
                                <Icon medium>
                                    directions_car
                                </Icon>
                            </h2>
                            <h5 className="center">Pronájem vozů</h5>
                            <p className="light">
                                Bleskový pronájem vozů pro Uber a jiné za ty nejnižší ceny. Vozy od 2700,-/ týdně s kompletním servisem a pojištěním.
                                Stačí si vybrat z naší nabídky vozů výše. Pokud vás však zajímá, kdy bude dostupný určitý vůz z
                                celé naší nabídky, kontaktujte nás.
                            </p>
                        </div>
                    </Col>
                    <Col s={12} m={6}>
                        <div className="icon-block center">
                            <h2 className="center red-text darken-1">
                                <Icon medium>
                                    attach_money
                                </Icon>
                            </h2>
                            <h5 className="center">Vše zařídíme</h5>
                            <p className="light">
                                S námi se nemusíte o nic starat. Bez prodlení vám pronajmeme vůz za tu nejlevnější cenu na trhu. Vše vám vysvětlíme,
                                 aby vám šlo podníkání levou zadní. Poskytujeme i zastřešení živnosti. Nedělejte nikomu otroka a přidejte se k nám.
                            </p>
                        </div>
                    </Col>
                </Row>
            </section>
        )
    }
}

export default Services;