import React from 'react';
import { Container } from "react-materialize";

// Components
import Header from "./components/Header.jsx";
import CustomFooter from "./components/CustomFooter.jsx";
import Services from "./components/Services.jsx";
import About from "./components/About.jsx";
import Carousel from "./components/Carousel.jsx";
import AvailableCars from "./components/AvailableCars.jsx";

class App extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <Carousel />
        <Container>
          <AvailableCars />
          <Services />
          <About />
        </Container>
        <CustomFooter />
      </div >
    );
  }
}

export default App;
