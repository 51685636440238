import React from "react";
import { Card, CardTitle, Divider, Row, Preloader, Button, Chip } from "react-materialize";
import Firebase from "./firebase.jsx";
import M from 'materialize-css/dist/js/materialize.js';
import "../assets/css/custom.css";

class AvailableCars extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cars: [],
            limit: 4,
            loading: true
        }

        this._mounted = false;
    }

    componentDidMount() {
        this._mounted = true;
        this.getCars();
    }

    componentWillMount() {
        this._mounted = true;
    }

    async getCars() {
        if (this._isMounted) {
            this.setState({ cars: [] })
        }

        const elem = document.querySelector('.carousel');
        const sessionData = JSON.parse(sessionStorage.getItem("uberrentData"));
        const { cars } = this.state;

        if (!sessionData) {
            await Firebase.allCarsRef().where("show", "array-contains", "uberrent").orderBy("year", "desc").get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        this.setState({
                            cars: [...this.state.cars, doc.data()],
                        })
                    });
                    sessionStorage.setItem("uberrentData", JSON.stringify(this.state.cars));
                    this.setState({ loading: false })
                })
                .catch(err => {
                    //error
                });
        } else {
            await sessionData.forEach(c => {
                cars.push(c);
            })
            this.setState({ cars, loading: false });
        }

        if (!this.state.loading) {
            M.Carousel.init(elem, {
                fullWidth: false,
                numVisible: 8,
                shift: 10,
                dist: -50,
                noWrap: false
            });
        }
    }

    getStatusColor = (sc) => {
        let color = "grey";
        switch (sc) {
            case "primary":
                color = "blue";
                break;
            case "danger":
                color = "red";
                break;
            case "warning":
                color = "orange";
                break;
            case "success":
                color = "green";
                break;
            default:
                color = "grey";
                break;
        }
        return color;
    }

    render() {
        const { loading, cars } = this.state;
        return (
            <section id="available-cars" className="section scrollspy">
                <h3 className="center">Nabídka vozidel</h3>
                <Divider />
                <Row>
                    <div className="carousel carousel-slider center" style={{ height: 550 }}>
                        {cars.map((car, index) => {
                            return !loading ? (
                                <div className="carousel-item center" key={index} style={{ width: 340, padding: 20 }}>
                                    <Card
                                        header={<CardTitle image={car.image.url} />}
                                        reveal={
                                            <div>
                                                <p>Rok výroby: {car.year}</p>
                                                <Divider />
                                                <p>Palivo: {car.fuel}</p>
                                                <Divider />
                                                <p>Motor: {car.motor}</p>
                                                <Divider />
                                                <p>Barva: {car.color}</p>
                                                <Divider />
                                                <p>Spotřeba: {car.consumption}</p>
                                                <Divider />
                                                <p>Výbava: {car.equipment}</p>
                                            </div>
                                        }
                                        actions={[
                                            <div className="center" key={index}>
                                                <Button className="activator red white-text waves-effect">Info o autě</Button>
                                            </div>
                                        ]}
                                    >
                                        <span className="card-title grey-text text-darken-4 center">{car.name}</span>
                                        {car.status ? <Chip style={{ backgroundColor: this.getStatusColor(car.statusColor), color: "white" }}>{car.status}</Chip> : null}
                                        <p style={{ fontSize: "larger" }} className="red-text center">Cena {car.price}</p>
                                    </Card>
                                </div>
                            ) : null
                        })}
                        {loading ? <div><br /><Preloader size="big" /></div> : null}
                    </div>
                </Row>
            </section>
        )
    }
}

export default AvailableCars;